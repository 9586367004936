
import Vue from 'vue'
import { mapActions } from 'vuex'
import Button from '../common/buttons/Button.vue'
export default Vue.extend<Data, Methods, Computed>({
  components: { Button },
  methods: {
    ...mapActions('HomeStore', ['fetchInitScroll', 'fetchInit']),
    goMainPage() {
      this.fetchInit()
      this.fetchInitScroll()
      this.$router.push('/')
    },
  },
})
interface Data {}
interface Methods {
  fetchInit(): void
  fetchInitScroll(): void
  goMainPage(): void
}
interface Computed {}
